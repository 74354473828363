body {
  margin: 0px;
  background-color: #e1a62a;
}

.intro {
  width: 100%;
  text-align: center;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px;
}

.title {
  color: white;
  font-family: Didot;
  font-style: italic;
  font-size: 4.0em;
  margin: 0px;
}

.subtitle {
  color: white;
  font-size: 1.5em;
  font-family: Palatino;
}

.button {
  background-color: #e1a62a;
  padding: 20px 30px;
  border: none;
  border-radius: 5px;
  margin: 10px 10px 5px 5px;
  font-size: 1.1em;
  font-family: Palatino;
  cursor: pointer;
  transition: filter 0.3s ease;
}

.button:hover {
  filter: opacity(80%);
}

.button.active {
  background-color: #e6b24e;
}

.summary {
  font-family: Palatino;
  color: #e1a62a;
  background-color: black;
  width: 50%;
 /* max-width: 450px; */
  text-align: left;
  justify-content: left;
  margin: 50px;
  padding: 10px 40px 30px 40px;
  scale: .8; opacity: 0;
  animation: fade-in linear forwards;
  animation-fill-mode: forwards;
  animation-timeline: view();
  animation-range: 250px 500px;
}

.contactBox {
  font-family: Palatino;
  background-color: white;
  color: black;
  width: 40%;
  max-width: 250px;
  text-align: left;
  margin-right: 50px;
  margin-left: auto;
  padding: 10px 70px 5px 40px;
  scale: .8; opacity: 0;
  animation: fade-in linear forwards;
  animation-fill-mode: forwards;
  animation-timeline: view();
  animation-range: 150px 300px;
}

@media only screen and (max-width: 500px) {
  .contactBox {
    width: 50%;
  }
}

.contactTitle {
  color: black;
  font-size: 2.0em;
  font-family: Palatino;
  padding: 10px 0px 30px 0px;
  text-decoration: underline;
  text-align: left;
}

.contactHead {
  font-size: 1.6em;
  margin-bottom: 15px;
  margin-left: 10px;
}

.contactBody {
  font-size: 1.2em;
  margin-bottom: 40px;
  margin-left: 10px;

}

@keyframes fade-in {
  to {scale: 1; opacity: 1;}
}

.button-trailer {
  font-family: Palatino;
  background-color: #e1a62a;
  padding: 20px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  cursor: pointer;
  transition: filter 0.3s ease;
}

.button-trailer:hover {
  filter: opacity(80%);
}

.background-one {
  background-color: rgba(0,0,0,0.3);
  background-blend-mode: multiply;
  background-image: url("./assets/cruel-1.png");
  background-position: center;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}

.background-two {
  background-color: rgba(255, 200, 0, 0.4);
  background-blend-mode: multiply;
  background-image: url("./assets/cruel-2.png");
  background-position: center;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}

.background-three {
  background-image: url("./assets/cruel-3.jpeg");
  background-position: center;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 200px;
}
